import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router'
import Itemturno from '../itemturno/itemturno'
import Busquedaturno from '../busquedaturno/busquedaturno'
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { ForcedLogOutAuthAction } from "../../redux/actions/AuthAction";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Buscarturno(props) {

    let history = useHistory();

    const { logout } = props

    let query = useQuery();
    const id_turno = query.get("id_turno")
    const refresh = query.get("refresh")


    const [isThereError, setisThereError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [cargando, setCargando] = useState(false)
    const [turno, setTurno] = useState("")

    const setError = (mensaje) => {
        setisThereError(true)
        setErrorMessage(mensaje)
    }

    const unsetError = () => {
        setisThereError(false)
        setErrorMessage("")
    }

    useEffect(() => {



        setTurno("")
        const obtenerTurnosDia = async () => {

            unsetError()
            setCargando(true)

            let url = 'https://centroeste.reviturnos.com.ar/api/auth/tur'

            const { user } = JSON.parse(localStorage.getItem("auth"))
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user.access_token
            }

            const datos = {
                id_turno
            }

            fetch(url, { method: 'POST', headers, body: JSON.stringify(datos) })
                .then(async res => {

                    if (res.status === 200) {
                        const respuesta = await res.json();

                        if (respuesta) {


                            setTurno(respuesta)
                            setCargando(false)
                            unsetError()
                        }


                    } else {
                        if (res.status === 500) {
                            logout(history)
                        }
                        const error = await res.json();
                        setCargando(false)
                        setError(error.mensaje)

                    }

                })
                .catch((error) => {

                    console.log("hola")

                    setCargando(false)
                    setError(error.message)
                })
        }
        if (id_turno !== null) {
            obtenerTurnosDia()
        }

    }, [id_turno, refresh])


    return (
        <div style={{ position: "relative", minHeight: "600px" }}>
            <h3>Buscar turno</h3>
            <br />

            {
                isThereError && <>
                    <div style={{ position: "relative" }} className="alert alert-danger" role="alert">
                        {errorMessage}
                    </div></>
            }

            {
                cargando && <div style={{ width: "100%", textAlign: "center", marginTop: "50px" }}>


                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }

            {turno ?
                <Itemturno datos={turno} /> : <Busquedaturno />}

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: (history) => {
            dispatch(ForcedLogOutAuthAction(history));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Buscarturno);
