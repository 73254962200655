import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router'
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { ForcedLogOutAuthAction } from "../../redux/actions/AuthAction";


function Formcrearturno(props) {

    let history = useHistory();

    const { logout } = props


    const [isThereError, setisThereError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [cargando, setCargando] = useState(false)
    const [dominio, setDominio] = useState("")
    const [tipoVehiculo, setTipoVehiculo] = useState("MOTO HASTA 300 CC")
    const [nombre, setNombre] = useState("")
    const [email, setEmail] = useState("")
    const [marca, setMarca] = useState("")
    const [modelo, setModelo] = useState("")
    const [anio, setAnio] = useState("")
    const [combustible, setCombustible] = useState("")
    const [dominioValido, setDominioValido] = useState(false)
    const [nombreValido, setNombreValido] = useState(false)
    const [emailValido, setEmailValido] = useState(false)
    const [marcaValido, setMarcaValido] = useState(false)
    const [modeloValido, setModeloValido] = useState(false)
    const [anioValido, setAnioValido] = useState(false)
    const [combustibleValido, setCombustibleValido] = useState(false)
    const [datosOk, setDatosOk] = useState(false)
    const [redirigir, setRedirigir] = useState("")

    const setError = (mensaje) => {
        setisThereError(true)
        setErrorMessage(mensaje)
    }

    const unsetError = () => {
        setisThereError(false)
        setErrorMessage("")
    }

    const handleChangeDominio = (e) => {

        var result = /^[a-zA-Z0-9-]{1,10}$/.test(e.target.value);
        setDominio(e.target.value)
        if (result) {
            setDominioValido(true)
        } else {
            setDominioValido(false)
        }

        if (result && nombreValido && emailValido && marcaValido && modeloValido && anioValido && combustibleValido) {
            setDatosOk(true)
        } else {
            setDatosOk(false)
        }

    }

    const handleChangeEmail = (e) => {

        var result = /^[-\w.%+]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,125}[a-zA-Z]{2,63}$/.test(e.target.value);
        setEmail(e.target.value)
        if (result) {
            setEmailValido(true)
        } else {
            setEmailValido(false)
        }

        if (result && nombreValido && dominioValido && marcaValido && modeloValido && anioValido && combustibleValido) {
            setDatosOk(true)
        } else {
            setDatosOk(false)
        }

    }

    const handleChangeMarca = (e) => {

        var result = /^[A-Za-z\s]{1,40}$/.test(e.target.value);
        setMarca(e.target.value)
        if (result) {
            setMarcaValido(true)
        } else {
            setMarcaValido(false)
        }

        if (result && nombreValido && dominioValido && emailValido && modeloValido && anioValido && combustibleValido) {
            setDatosOk(true)
        } else {
            setDatosOk(false)
        }

    }

    const handleChangeModelo = (e) => {

        var result = /^[0-9A-Za-z\s\.-]{1,50}$/.test(e.target.value);
        setModelo(e.target.value)
        if (result) {
            setModeloValido(true)
        } else {
            setModeloValido(false)
        }

        if (result && nombreValido && dominioValido && emailValido && marcaValido && anioValido && combustibleValido) {
            setDatosOk(true)
        } else {
            setDatosOk(false)
        }

    }

    const handleChangeAnio = (e) => {

        var result = /^[0-9]{4}$/.test(e.target.value);
        setAnio(e.target.value)
        if (result) {
            setAnioValido(true)
        } else {
            setAnioValido(false)
        }

        if (result && nombreValido && dominioValido && emailValido && marcaValido && modeloValido && combustibleValido) {
            setDatosOk(true)
        } else {
            setDatosOk(false)
        }

    }

    const handleChangeCombustible = (e) => {

        var result = /^[A-Za-z\s]{1,30}$/.test(e.target.value);
        setCombustible(e.target.value)
        if (result) {
            setCombustibleValido(true)
        } else {
            setCombustibleValido(false)
        }

        if (result && nombreValido && dominioValido && emailValido && marcaValido && modeloValido && anioValido) {
            setDatosOk(true)
        } else {
            setDatosOk(false)
        }

    }

    const handleChangeNombre = (e) => {

        var result = /^[A-Za-z\s]{2,100}$/.test(e.target.value);
        setNombre(e.target.value)
        if (result) {
            setNombreValido(true)
        } else {
            setNombreValido(false)
        }
        if (result && dominioValido && emailValido && marcaValido && modeloValido && anioValido && combustibleValido) {
            setDatosOk(true)
        } else {
            setDatosOk(false)
        }

    }

    const handleSubmit = () => {

        const crearTurno = async () => {

            unsetError()
            setCargando(true)

            let url = 'https://centroeste.reviturnos.com.ar/api/auth/creTur'

            const { user } = JSON.parse(localStorage.getItem("auth"))
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user.access_token
            }

            const anioInt = parseInt(anio)
            const datos = {
                dominio,
                nombre,
                tipo_vehiculo: tipoVehiculo,
                email,
                marca,
                modelo,
                anio: anioInt,
                combustible
            }

            fetch(url, { method: 'POST', headers, body: JSON.stringify(datos) })
                .then(async res => {

                    if (res.status === 200) {
                        const respuesta = await res.json();

                        if (respuesta) {


                            console.log(respuesta)
                            setCargando(false)
                            unsetError()
                            const redireccion = "/buscarturno?id_turno=" + respuesta.id
                            setRedirigir(redireccion)
                        }


                    } else {
                        if (res.status === 500) {
                            logout(history)
                        }
                        const error = await res.json();
                        setCargando(false)
                        setError(error.mensaje)

                    }

                })
                .catch((error) => {
                    setCargando(false)
                    setError(error.message)
                })
        }
        crearTurno()

    }

    useEffect(() => {

    }, [redirigir])

    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Dominio</label>
                        <input value={dominio} onChange={handleChangeDominio} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                    </div>
                    <div className="form-group">
                        <label for="exampleFormControlSelect1">Tipo de vehiculo</label>
                        <select onChange={(e) => setTipoVehiculo(e.target.value)} className="form-control" id="exampleFormControlSelect1">
                            {/* <option>Default select</option> */}
                            {

                                props.tipos.map((tipo, indice) => {
                                    return <option key={indice}>{tipo.descripcion}</option>
                                })

                            }
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="input-nombre">Nombre</label>
                        <input value={nombre} onChange={handleChangeNombre} type="text" className="form-control" id="input-nombre" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="input-email">Email</label>
                        <input value={email} onChange={handleChangeEmail} type="email" className="form-control" id="input-email" />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="input-marca">Marca</label>
                        <input value={marca} onChange={handleChangeMarca} type="text" className="form-control" id="input-marca" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="input-modelo">Modelo</label>
                        <input value={modelo} onChange={handleChangeModelo} type="text" className="form-control" id="input-modelo" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="input-anio">Año</label>
                        <input value={anio} onChange={handleChangeAnio} type="text" className="form-control" id="input-anio" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="input-combustible">Combustible</label>
                        <input value={combustible} onChange={handleChangeCombustible} type="text" className="form-control" id="input-combustible" />
                    </div>
                </div>
            </div>



            <br />
            {
                datosOk && <button onClick={handleSubmit} type="button" className="btn btn-primary">Crear</button>
            }

            {
                cargando && <div style={{ width: "100%", textAlign: "center", marginTop: "50px" }}>


                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }

            {
                isThereError && <>
                    <div style={{ position: "relative" }} className="alert alert-danger" role="alert">
                        {errorMessage}
                    </div></>
            }

            {
                redirigir && <Redirect to={redirigir} />
            }


        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: (history) => {
            dispatch(ForcedLogOutAuthAction(history));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Formcrearturno);
