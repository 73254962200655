import React from 'react'

function DayList({ turnos}) {
  return (
    <div style={{ padding: "30px" }}>
      <table className="table table-striped table-dark">
        <thead >
          <tr>
            <th scope="col">Orden</th>
            <th scope="col">Fecha</th>
            <th scope="col">Hora</th>
            <th scope="col">Dominio</th>
            <th scope="col">Nombre</th>
            <th scope="col">Tipo vehiculo</th>
            {/* <th scope="col">Telefono</th> */}
          </tr>
        </thead>
        <tbody>
          {turnos.map((turno, index) => 
            (
                <tr key={ turno.id}>
                <td>{index+1}</td>
                <th scope="row" >{ turno.fecha}</th>
                <td>{turno.hora.substr(0,5)}</td>
                <td>{turno.datos.dominio}</td>
                <td>{turno.datos.nombre}</td>
                <td>{turno.datos.tipo_vehiculo}.-</td>
                {/* <td>{turno.datos.telefono}</td> */}
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  )
}

export default DayList
