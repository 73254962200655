import React, { useState } from 'react'
import { Redirect } from 'react-router'
import Listadoturnos from '../listadoturnos/listadoturnos'
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { ForcedLogOutAuthAction } from "../../redux/actions/AuthAction";



function Busquedaturno(props) {

    let history = useHistory();

    const { logout } = props

    const [cadenaBusqueda, setCadenaBusqueda] = useState("")
    const [campo, setCampo] = useState("")
    const [isThereError, setisThereError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [redireccion, setRedireccion] = useState("")
    const [cargando, setCargando] = useState(false)
    const [turnos, setTurnos] = useState("")

    const setError = (mensaje) => {
        setisThereError(true)
        setErrorMessage(mensaje)
    }

    const unsetError = () => {
        setisThereError(false)
        setErrorMessage("")
    }

    const handleChangeCadena = (e) => {
        setCadenaBusqueda(e.target.value)
        const id = /^[0-9]{1,10}$/.test(e.target.value);
        if (id) {
            setCampo("id")
        } else {
            const dominio = /^[a-zA-Z0-9\-]{1,15}$/.test(e.target.value);
            if (dominio) {
                setCampo("dominio")
            } else {
                setCampo("")
            }
        }
    }

    const handleBuscarTurno = () => {
        var url = "error"
        console.log(campo)

        if (campo === "id") {
            url = "https://centroeste.reviturnos.com.ar/api/auth/turId?id_turno=" + cadenaBusqueda
        } else {
            if (campo === "dominio") {
                url = "https://centroeste.reviturnos.com.ar/api/auth/turDom?dominio=" + cadenaBusqueda
            }
        }

        if (url === "error") {
            setError("El dato no corresponde a ningun parametros de busqueda")
            return;
        }

        const buscarTurno = async (url) => {

            setTurnos("")
            unsetError()
            setCargando(true)

            const { user } = JSON.parse(localStorage.getItem("auth"))
            const headers = {
                'Authorization': 'Bearer ' + user.access_token
            }

            fetch(url, { method: 'GET', headers })
                .then(async res => {

                    if (res.status === 200) {
                        const respuesta = await res.json();

                        if (respuesta) {
                            if (respuesta.tipo === "id") {
                                setCargando(false)
                                unsetError()
                                let destino = "/buscarturno?id_turno=" + respuesta.id
                                setRedireccion(destino)
                            }
                            if (respuesta.tipo === "dominio") {
                                setCargando(false)
                                unsetError()
                                setTurnos(respuesta.turnos)
                            }


                        }


                    } else {
                        if (res.status === 500) {
                            logout(history)
                        }
                        const error = await res.json();
                        setCargando(false)
                        setError(error.mensaje)

                    }

                })
                .catch((error) => {
                    setCargando(false)
                    setError(error.message)
                })
        }
        buscarTurno(url)

    }


    return (
        <div>

            <div className="form-group">
                <label htmlFor="input-nombre">Ingrese ID o Dominio:</label>
                <input value={cadenaBusqueda} onChange={handleChangeCadena} type="text" className="form-control" id="input-nombre" />
            </div>

            {campo && <button onClick={handleBuscarTurno} type="button" className="btn btn-dark">Buscar {campo}</button>}

            {
                cargando && <div style={{ width: "100%", textAlign: "center", marginTop: "50px" }}>


                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }



            {turnos && <><br /><br /><Listadoturnos turnos={turnos} /></>}

            {
                isThereError && <>
                    <div style={{ position: "relative" }} className="alert alert-danger" role="alert">
                        {errorMessage}
                    </div></>
            }

            {redireccion && <Redirect to={redireccion} />}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: (history) => {
            dispatch(ForcedLogOutAuthAction(history));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Busquedaturno);
