import React from "react";

function Footer() {
  return (
    <div className="footer">
      {/* <div className="container footer-container"> */}
        Admin RTO Rivadavia - v1.0.1
      {/* </div> */}
    </div >
  );
}

export default Footer;
